import Cta from '@components/Cta'
import { Html5Qrcode } from 'html5-qrcode'
import { useRef, useState } from 'react'
import { useRouter } from 'next/router'
import Header from '@components/Header'
import { isMobile } from 'react-device-detect'
import { Html5QrcodeCameraScanConfig } from 'html5-qrcode/esm/html5-qrcode'

export default function Home() {
  const qrCodeScannerId = 'qrcode-wrapper'
  const router = useRouter()
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false)
  const qrCodeReaderRef = useRef<Html5Qrcode>(null)

  const onQrCodeSuccess = (decodedText: string) => {
    if (!isRedirecting) {
      try {
        console.log(decodedText)

        const url = new URL(decodedText)
        const hardwareId = url.searchParams.get('hardwareId');

        setIsRedirecting(true)
        qrCodeReaderRef.current.stop()

        return router.push(`/welcome?hardwareId=${hardwareId}`)
      } catch (error) {
        return router.push('/error')
      }
    }
  }

  const onQrCodeError = () => {
    console.log('qr code error')
  }

  const getCameraPermission = () => {
    Html5Qrcode.getCameras()
      .then((devices) => {
        if (devices && devices.length) {
          renderQrCodeScanner(devices[devices.length - 1].id)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const renderQrCodeScanner = (cameraId: string) => {
    qrCodeReaderRef.current = new Html5Qrcode(qrCodeScannerId)
    const qrCodeWrapper = document.getElementById(qrCodeScannerId)
    const { height, width } = qrCodeWrapper.getBoundingClientRect()

    const qrCodeScannerConfig: Html5QrcodeCameraScanConfig = {
      fps: 10,
      qrbox: { height, width },
    }

    if (isMobile) {
      qrCodeScannerConfig.videoConstraints = { facingMode: { exact: 'environment' } }
    }

    qrCodeReaderRef.current.start(cameraId, qrCodeScannerConfig, onQrCodeSuccess, onQrCodeError).catch((err) => {
      console.error(err)
    })
  }

  return (
    <div className="px-4 bg-blurred bg-center bg-cover bg-no-repeat h-screen">
      <Header />
      <div
        id={qrCodeScannerId}
        className="overflow-hidden px-[42px] flex items-center justify-center text-center flex-col bg-[#1D1A27] border rounded border-transparent h-[350px] mb-4 mt-4 text-white"
      >
        <p className="text-base font-medium mb-6 px-[5.5px]">Degen Arcade needs permission to access your camera</p>
        <Cta onClick={getCameraPermission}>Allow Access to Camera</Cta>
      </div>
      <div className="text-white text-body2 font-seminormal text-center px-4">
        Please point your camera at the QR code on the game you wish to play
      </div>
    </div>
  )
}
